<template>
  <div id="app">
    <div class="app-wrapper">
      <div>
        <div class="track">
          <figure class="logo"></figure>
          <div class="wrapper">
            <div class="search">
              <input ref="boxinput" type="text" placeholder="请输入提单号/箱号">
              <button @click="search">
                <span  role="img" aria-label="search" class="anticon anticon-search"><svg focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span>
                查询
              </button>
            </div>
          </div>
        </div>
        <el-empty v-if="!boxdata" description="请输入正确单号/箱号，进行查询~"></el-empty>
        <div class="inquire" v-if="boxdata">
          <div class="base">
            <h2 class="title">基本信息</h2>
            <div class="base-title">
              <div >
                <span class="number">类型：</span>
                <el-select size="mini" v-model="type" placeholder="请选择" @change="typechange">
                  <el-option  key="JK" label="进口" value="JK"> </el-option>
                  <el-option  key="CK" label="出口" value="CK"> </el-option>
                  <el-option  key="ZZ" label="中转" value="ZZ"> </el-option>
                </el-select>
              </div>
              <div  class="number">{{boxdata.head?.type==0?"提单号":"箱号"}}：{{boxnumber}}</div>
            </div>
            <div class="container">
              <div class="address" v-show="type=='JK'">
                <div class="item-chuan">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont gk"></i>
                      <span >装货港</span>
                    </div>
                    <div class="start-content">
                      <div ><div  class="place strong-600">{{boxdata.head.startPortName|emptyToPlaceholder}}</div></div>
                    </div>
                  </div>
                  <em class="right-j r0"></em>
                </div>
                <div class="item-che">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont gk"></i><span >卸货港</span>
                    </div>
                    <div  class="start-content">
                      <div>
                        <div class="place strong-600">{{boxdata.head.endPortName|emptyToPlaceholder}}</div>
                      </div>
                      <div class="date timer">
                        <span >预计到港时间：</span>
                        <span class="strong-600">{{boxdata.head.eta|emptyToPlaceholder}}</span>
                      </div>
                      <div class="Wharf timer">
                        <span>实际到港时间：</span>
                        <span class="strong-600">{{boxdata.head.ata|emptyToPlaceholder}}</span>
                      </div>
                    </div>
                  
                  </div>
                  <em class="right-j r1"></em>
                </div>
                <div class="item-che">
                  <div class="item">
                    <div  class="start-icon">
                      <i  class="iconfont gc"></i>
                      <span >厂家</span>
                    </div>
                    <div  class="start-content">
                      <div >
                        <div  class="place strong-600 blue">暂无数据</div>
                      </div>
                      <div  class="date timer">
                        <span >预计到达时间：</span>
                        <span  class="strong-600 nothing">暂无数据</span>
                      </div>
                      <div  class="Wharf timer">
                        <span >实际到达时间：</span>
                        <span  class="strong-600 nothing">暂无数据</span>
                      </div>
                    </div>
                  </div>
                  <em  class="right-j r2"></em><!----></div>
              </div>
              <div class="address" v-show="type=='CK'" >
                <div class="item-che">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont cz"></i>
                      <span>场站</span>
                    </div>
                    <div class="start-content">
                      <div>
                        <div class="place strong-600">{{boxdata.head.oneXzCode|emptyToPlaceholder}}</div>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r0"></em>
                </div>
                <div class="item-chuan">
                  <div class="item ZZ">
                    <div class="place strong-600 blue title-place">QIND DAO</div>
                    <div class="start-content">
                      <div class="date timer">
                        <span>收箱开始时间：</span>
                        <span class="strong-600 nothing">暂无数据</span>
                      </div>
                      <div class="Wharf timer">
                        <span>收箱结束时间：</span>
                        <span class="strong-600 nothing">暂无数据</span>
                      </div>
                    </div>
                    <div class="start-icon">
                      <i class="iconfont add"></i>
                      <span>装货港</span>
                    </div>
                    <div class="start-content">
                      <div class="date timer">
                        <span>预计到港时间：</span>
                        <span class="strong-600">{{boxdata.head.eta|emptyToPlaceholder}}</span>
                      </div>
                      <div class="Wharf timer">
                        <span>预计离港时间：</span>
                        <span class="strong-600">{{boxdata.head.etd|emptyToPlaceholder}}</span>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r1"></em>
                </div>
                <div class="item-chuan">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont xie"></i>
                      <span>卸货港</span>
                    </div>
                    <div class="start-content">
                      <div>
                        <div class="place strong-600">{{boxdata.head.endPortName|emptyToPlaceholder}}</div>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r2"></em>
                </div>
              </div>
              <div class="address" v-show="type=='ZZ'">
                <div class="item-chuan">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont gk"></i>
                      <span>装货港</span>
                    </div>
                    <div class="start-content">
                      <div>
                        <div class="place strong-600">{{boxdata.head.startPortName}}</div>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r0"></em>
                </div>
                <div class="item-chuan">
                  <div class="item ZZ">
                    <div class="place strong-600 blue title-place">QIND DAO</div>
                    <div class="start-content">
                      <div class="date timer">
                        <span>预计到港时间：</span>
                        <span class="strong-600">暂无数据</span>
                      </div>
                      <div class="Wharf timer">
                        <span>实际到港时间：</span>
                        <span class="strong-600">暂无数据</span>
                      </div>
                    </div>
                    <div class="start-icon">
                      <i class="iconfont xie"></i>
                      <span>中转港</span>
                    </div>
                    <div class="start-content">
                      <div class="date timer">
                        <span>预计离港时间：</span>
                        <span class="strong-600">暂无数据</span>
                      </div>
                      <div class="Wharf timer">
                        <span>实际离港时间：</span>
                        <span class="strong-600">暂无数据</span>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r1"></em>
                </div>
                <div class="item-che">
                  <div class="item">
                    <div class="start-icon">
                      <i class="iconfont gk"></i>
                      <span>卸货港</span>
                    </div>
                    <div class="start-content">
                      <div>
                        <div class="place strong-600">ROTTERDAM</div>
                      </div>
                    </div>
                  </div>
                  <em class="right-j r2"></em>
                </div>
              </div>
            </div>
            <div class="">
              <div v-if="type!='ZZ'" class="intro">
                <div>
                  <div> 中文船名:
                    <span class="strong-600 link">{{boxdata.head.oneCnShipName}}</span>
                  </div>
                  <span class="location-icon nav-cursor"></span>
                </div>
                <div>
                  <div> 英文船名：<strong class="link strong-600">{{boxdata.head.oneEnShipName}}</strong>
                  </div>
                  <span class="location-icon nav-cursor"></span>
                </div>
                <div> 停靠位置：<strong class="strong-600">{{boxdata.head.oneStopPosition}}</strong>
                </div>
                <div>
                  <div> 航次：<strong class="link strong-600">{{boxdata.head.oneHc}}</strong>
                  </div>
                </div>
                <div> IMO：<strong class="strong-600">{{boxdata.head.oneImo}}</strong>
                  <span class="location-icon nav-cursor"></span>
                </div>
              </div>
              <div v-else class="intro-view">
                <div class="intro">
                  <div>
                    <h4>一程船</h4>
                  </div>
                  <div>
                    <div> 中文船名:<span class="strong-600 link">{{boxdata.head.oneCnShipName}}</span></div>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                  <div>
                    <div> 英文船名：<strong class="link strong-600">{{boxdata.head.oneEnShipName}}</strong>
                    </div>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                  <div> 停靠位置：<strong class="strong-600">{{boxdata.head.oneStopPosition}}</strong>
                  </div>
                  <div>
                    <div> 航次：<strong class="link strong-600">{{boxdata.head.oneHc}}</strong></div>
                  </div>
                  <div> IMO：<strong class="strong-600">{{boxdata.head.oneImo|emptyToPlaceholder}}</strong>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                </div>
                <div class="intro">
                  <div>
                    <h4>二程船</h4>
                  </div>
                  <div>
                    <div> 中文船名:<span class="strong-600 link">{{boxdata.head.twoCnShipName}}</span></div>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                  <div>
                    <div> 英文船名：<strong class="link strong-600">{{boxdata.head.twoEnShipName}}</strong>
                    </div>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                  <div> 停靠位置：<strong class="strong-600">{{boxdata.head.twoStopPosition}}</strong>
                  </div>
                  <div>
                    <div> 航次：<strong class="link strong-600">{{boxdata.head.twoHc}}</strong></div>
                  </div>
                  <div> IMO：<strong class="strong-600">{{boxdata.head.twoImo|emptyToPlaceholder}}</strong>
                    <span class="location-icon nav-cursor"></span>
                  </div>
                </div>
                
              </div>
            </div>

          </div>
          <div class="trends">
            <div class="ant-tabs ant-tabs-top css-eq3tly ant-tabs-card ant-tabs-editable-card ant-tabs-editable">
              <div class="ant-tabs-nav">
                <div class="ant-tabs-nav-wrap">
                  <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px);">
                    <div class="ant-tabs-tab ant-tabs-tab-active">
                      <div role="tab" aria-selected="true" id="rc-tabs-0-tab-QGD0548594" class="ant-tabs-tab-btn" aria-controls="rc-tabs-0-panel-QGD0548594" tabindex="0">
                        {{boxdata.head?.type==0?"提单号":"箱号"}}：{{boxnumber}}
                      </div>
                    </div>
                    <div class="ant-tabs-ink-bar ant-tabs-ink-bar-animated" style="left: 0px; width: 173px;">
                    </div>
                  </div>
                </div>
                <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
                  <button type="button" class="ant-tabs-nav-more" tabindex="-1" aria-hidden="true" aria-haspopup="listbox" aria-controls="rc-tabs-0-more-popup" id="rc-tabs-0-more" aria-expanded="false" style="visibility: hidden;order: 1;"><span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis"><svg focusable="false" class="" data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path></svg></span></button>
                </div>
              </div>
              <div class="ant-tabs-content-holder">
                <div class="ant-tabs-content ant-tabs-content-top">
                  <div class="ant-tabs-tabpane ant-tabs-tabpane-active">
                    <div class="info">
                      <div class="bill" isall="true">
                        <div class="header">
                          <h1>动态信息</h1>
                          <div class="all active"> 
                            <el-switch
                              v-model="isallinfo"
                              active-text="全部信息"
                              active-color="#1677ff"
                              inactive-color="#dbe7ff">
                            </el-switch>
                          </div>
                        </div>
                        <div v-show="isallinfo" class="allbg">全部信息</div>
                        <ul class="trends-list" >
                          <li v-for="(item,index) in liList" :key="index" class="nav-cursor " :class="{'active':item.active}" @click="handleListClick(item.title)">
                            <i class="iconfont"></i>
                            <div class="flow ">
                              <div class="content">
                                <div class="ant-typography ant-typography-ellipsis ant-typography-single-line title css-eq3tly">{{item.title}}
                                </div>
                              </div>
                              <span class="nav-cursor top-btn"></span>
                            </div>
                          </li>
                          
                        </ul>
                      </div>
                      <div class="wharf-info-view">
                        <div v-show="!isallinfo" class="wharf-info">
                          <div class="wharf-header">
                            <h1><i class="iconfont">文</i>{{selectedTable}}</h1>
                          </div>
                          <div class="ant-table-wrapper css-eq3tly">
                            <div class="ant-spin-nested-loading css-eq3tly">
                              <el-table
                              v-show="selectedTable=='出口-码头信息'"
                                :data="mtxxListck"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                  prop="ZHGM"
                                  label="装货港">
                                </el-table-column>
                                <el-table-column
                                  prop="XHGM"
                                  label="卸货港">
                                </el-table-column>
                                <el-table-column
                                  prop="MDGM"
                                  label="目的港">
                                </el-table-column>
                                <el-table-column
                                  prop="XZDM"
                                  label="箱站代码">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="当前站名称">
                                </el-table-column>
                                <el-table-column
                                  prop="DFSJ"
                                  label="到发时间">
                                </el-table-column>
                                <el-table-column
                                  prop="XSGSM"
                                  label="箱属">
                                </el-table-column>
                                <el-table-column
                                  prop="CC"
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                  prop="XX"
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="QFH1"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                  prop="MZ"
                                  label="整箱重">
                                </el-table-column>
                                <el-table-column
                                  prop="KZ"
                                  label="空重">
                                </el-table-column>
                                <el-table-column
                                  prop="MTFX"
                                  label="码头放行">
                                </el-table-column>
                                <el-table-column
                                  prop="DQZTMC"
                                  label="当前状态">
                                </el-table-column>
                                <el-table-column
                                  prop="RGLB"
                                  label="入港类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="SJRGSJ"
                                  label="入港/单据录入时间">
                                </el-table-column>
                                <el-table-column
                                  prop="CGLB"
                                  label="出港类别	">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="SJCGSJ"
                                  label="出港时间	">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="CGMX"
                                  label="出港明细	">
                                </el-table-column>
                                <el-table-column
                                  prop="DCT"
                                  label="堆存天数	">
                                </el-table-column>
                                <el-table-column
                                  prop="GLDWMC"
                                  label="地理位置	">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="TSZZXQ"
                                  label="特殊装载需求">
                                </el-table-column>
                                <el-table-column
                                  prop="NWM"
                                  label="内外贸	">
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="IMO"
                                  label="危品IMO">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="LDXBZ"
                                  label="冷冻箱标志">
                                </el-table-column>
                                <el-table-column
                                  prop="LDWD"
                                  label="冷冻温度">
                                </el-table-column>
                                <el-table-column
                                  prop="SD"
                                  label="湿度">
                                </el-table-column>
                                <el-table-column
                                  prop="TF"
                                  label="通风">
                                </el-table-column>
                                <el-table-column
                                  prop="WPBZ"
                                  label="危品标志">
                                </el-table-column>
                                <el-table-column
                                  prop="GJWG"
                                  label="国际危规">
                                </el-table-column>
                                <el-table-column
                                  prop="RGMX"
                                  label="入港明细">
                                </el-table-column>
                                <el-table-column
                                  prop="SX"
                                  label="锁箱">
                                </el-table-column>
                                <el-table-column
                                  prop="CG"
                                  label="超高">
                                </el-table-column>
                                <el-table-column
                                  prop="ZC"
                                  label="左超">
                                </el-table-column>
                                <el-table-column
                                  prop="YC"
                                  label="右超">
                                </el-table-column>
                                <el-table-column
                                  prop="QC"
                                  label="前超">
                                </el-table-column>
                                <el-table-column
                                  prop="HC"
                                  label="后超">
                                </el-table-column>
                              </el-table>
                              <el-table
                              v-show="selectedTable=='出口-装箱单信息'"
                                :data="zxdListck"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop="MTMC"
                                  label="码头名称">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="ZWCM"
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XHGM"
                                  label="卸货港">
                                </el-table-column>
                                <el-table-column
                                  prop="XZDM"
                                  label="箱站代码">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XSTDH"
                                  label="分单号">
                                </el-table-column>
                                <el-table-column
                                  prop="NWM"
                                  label="内外贸">
                                </el-table-column>
                                <el-table-column
                                  prop="CC"
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                  prop="XX"
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                  prop="XSGSM"
                                  label="箱属">
                                </el-table-column>
                                <el-table-column
                                  prop="CZFS"
                                  label="称重方式">
                                </el-table-column>
                                <el-table-column
                                  prop="HZZT"
                                  label="回执状态">
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.HZZT=="T"?"成功":"失败" }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop="HZMS"
                                  label="回执描述">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="TQSBBZ"
                                  label="提前申报标志">
                                </el-table-column>
                                
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-运抵报告'"
                                :data="ydbgListck"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop="CIMO"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHBBH"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XZDM"
                                  label="箱站代码">
                                </el-table-column>
                                <el-table-column
                                  prop="XHDGQDM"
                                  label="关区代码">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  show-overflow-tooltip
                                  prop="HZMS"
                                  label="海关回执描述">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="IMPORT_TIME"
                                  label="发送时间">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="HZSJ"
                                  label="海关回执时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="整箱重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="货重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="体积">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="件数">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-在途监管信息'"
                                :data="ztjgListck"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="启运地">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="目的地">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="海关回执">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                                
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-海关报关单放行'"
                                :data="bgdfxListck"
                                style="width: 100%">
                                <el-table-column
                                width="250"
                                  prop="ENTRY_ID"
                                  label="报关单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="BILL_NO"
                                  label="分提单号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="FLAGHT_NO"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VOYAGE_NO"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CIMO"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                  prop="I_E_PORT"
                                  label="关区代码">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="R_DATE"
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                width="100"
                                  prop="PASS_PACK_NO"
                                  label="放行件数">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CONTA_ID"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                  prop="NET_WT"
                                  label="净重">
                                </el-table-column>
                                <el-table-column
                                  prop="GROS_WT"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="海关状态">
                                  放行
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-海关查验'"
                                :data="hgcyListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="出口航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="流向类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="入查验区时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="出查验区时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-装载放行'"
                                :data="zzfxListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CIMO"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="RDATE"
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                width="100"
                                  prop="SCBZMC"
                                  label="海关指令">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="IMPORT_TIME"
                                  label="接收时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-外理审核放行'"
                                :data="wlshListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CIMO"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="MAIN_BILL_NO"
                                  label="分单号">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-外理理货报告'"
                                :data="wllhListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CONT_NO"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="TRANSPORT_TOOL_NAME"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VESSEL_VOYAGE_NUMBER"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="TRANSPORT_TOOL_COD"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                  prop="DECLARE_CUSTOMS_COD"
                                  label="关区代码">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_NUMBER"
                                  label="件数">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_TOTAL_GROSS_WEIGHT"
                                  label="重数">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_CUBE"
                                  label="体积">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="EDI_FILE_READ_TIME"
                                  label="发送时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop="RESPONSE_CONT_MSG"
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="CIMO"
                                  label="回执时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="删除标志">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='出口-箱跟踪'"
                                :data="xgzListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="ZWCM"
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="JCCSJ"
                                  label="移动日期">
                                </el-table-column>
                                <el-table-column
                                  prop="JCCLBMC"
                                  label="移动类别">
                                </el-table-column>
                                <el-table-column
                                  prop="CCDWMC"
                                  label="移出单位">
                                </el-table-column>
                                <el-table-column
                                  prop="JCDWMC"
                                  label="移入单位">
                                </el-table-column>
                                <el-table-column
                                  prop="QFH"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="EDI_FILE_READ_TIME"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="车号">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-码头信息'"
                                :data="mtxxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                  prop="ZHGM"
                                  label="装货港">
                                </el-table-column>
                                <el-table-column
                                  prop="XHGM"
                                  label="卸货港">
                                </el-table-column>
                                <el-table-column
                                  prop="MDGM"
                                  label="目的港">
                                </el-table-column>
                                <el-table-column
                                  prop="XZDM"
                                  label="箱站代码">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="当前站名称">
                                </el-table-column>
                                <el-table-column
                                  prop="DFSJ"
                                  label="到发时间">
                                </el-table-column>
                                <el-table-column
                                  prop="XSGSM"
                                  label="箱属">
                                </el-table-column>
                                <el-table-column
                                  prop="CC"
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                  prop="XX"
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="QFH1"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                  prop="MZ"
                                  label="整箱重">
                                </el-table-column>
                                <el-table-column
                                  prop="KZ"
                                  label="空重">
                                </el-table-column>
                                <el-table-column
                                  prop="MTFX"
                                  label="码头放行">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="DQZTMC"
                                  label="当前状态">
                                </el-table-column>
                                <el-table-column
                                  prop="RGLB"
                                  label="入港类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="SJRGSJ"
                                  label="入港/单据录入时间">
                                </el-table-column>
                                <el-table-column
                                  prop="CGLB"
                                  label="出港类别	">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="SJCGSJ"
                                  label="出港时间	">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="CGMX"
                                  label="出港明细	">
                                </el-table-column>
                                <el-table-column
                                  prop="DCT"
                                  label="堆存天数	">
                                </el-table-column>
                                <el-table-column
                                  prop="GLDWMC"
                                  label="地理位置	">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="TSZZXQ"
                                  label="特殊装载需求">
                                </el-table-column>
                                <el-table-column
                                  prop="NWM"
                                  label="内外贸	">
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="IMO"
                                  label="危品IMO">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="LDXBZ"
                                  label="冷冻箱标志">
                                </el-table-column>
                                <el-table-column
                                  prop="LDWD"
                                  label="冷冻温度">
                                </el-table-column>
                                <el-table-column
                                  prop="SD"
                                  label="湿度">
                                </el-table-column>
                                <el-table-column
                                  prop="TF"
                                  label="通风">
                                </el-table-column>
                                <el-table-column
                                  prop="WPBZ"
                                  label="危品标志">
                                </el-table-column>
                                <el-table-column
                                  prop="GJWG"
                                  label="国际危规">
                                </el-table-column>
                                <el-table-column
                                  prop="RGMX"
                                  label="入港明细">
                                </el-table-column>
                                <el-table-column
                                  prop="SX"
                                  label="锁箱">
                                </el-table-column>
                                <el-table-column
                                  prop="CG"
                                  label="超高">
                                </el-table-column>
                                <el-table-column
                                  prop="ZC"
                                  label="左超">
                                </el-table-column>
                                <el-table-column
                                  prop="YC"
                                  label="右超">
                                </el-table-column>
                                <el-table-column
                                  prop="QC"
                                  label="前超">
                                </el-table-column>
                                <el-table-column
                                  prop="HC"
                                  label="后超">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-原始舱单'"
                                :data="yscdListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="MAIN_BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="BILL_OF_LADING_NO"
                                  label="分提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CONTAINER_NO"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="VESSEL_NAME_CN"
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="VESSEL_NAME_EN"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VOYAGE_NUMBER"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="FULL_EMPTY_ID"
                                  label="空重">
                                </el-table-column>
                                <el-table-column
                                  prop="CONTAINER_SIZE"
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                  prop="CONTAINER_TYPE"
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                  prop="SEAL_NO"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="CARGO_QUANTITY"
                                  label="件数">
                                </el-table-column>
                                <el-table-column
                                  prop="TOTAL_GROSS_WEIGHT"
                                  label="货重">
                                </el-table-column>
                                <el-table-column
                                  prop="TOTAL_VOLUMN"
                                  label="体积">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-场站信息'"
                                :data="czxxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="码头名称">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="箱站代码">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="整箱重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="空重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="海关放行">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="装货港">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="数据来源">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="当前状态">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="来源">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="卸船时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="去向">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="出港时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="堆存天数">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="特殊装载需求">
                                </el-table-column>
                                <el-table-column
                                width="100"
                                  prop=""
                                  label="内外贸">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-海关查验'"
                                :data="hgcyListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="出口航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="流向类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="入查验区时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="出查验区时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-箱跟踪'"
                                :data="xgzListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="ZWCM"
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="JCCSJ"
                                  label="移动日期">
                                </el-table-column>
                                <el-table-column
                                  prop="JCCLBMC"
                                  label="移动类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="CCDWMC"
                                  label="移出单位">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="JCDWMC"
                                  label="移入单位">
                                </el-table-column>
                                <el-table-column
                                  prop="QFH"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="EDI_FILE_READ_TIME"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="车号">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-外理理货报告'"
                                :data="wllhListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="CONT_NO"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="TRANSPORT_TOOL_NAME"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VESSEL_VOYAGE_NUMBER"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="TRANSPORT_TOOL_COD"
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                  prop="DECLARE_CUSTOMS_COD"
                                  label="关区代码">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_NUMBER"
                                  label="件数">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_TOTAL_GROSS_WEIGHT"
                                  label="重数">
                                </el-table-column>
                                <el-table-column
                                  prop="CARGO_CUBE"
                                  label="体积">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="EDI_FILE_READ_TIME"
                                  label="发送时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop="RESPONSE_CONT_MSG"
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="CIMO"
                                  label="回执时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="删除标志">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-查验委托信息'"
                                :data="wtcyListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="单证号码">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="检查地点">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="是否自备车">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-提单放行（码头进口放行的依据）'"
                                :data="hgxcdfxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="FLAGHT_NO"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VOYAGE_NO"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="支线英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="支线航次">
                                </el-table-column>
                                <el-table-column
                                  prop="ENTRY_ID"
                                  label="报关单号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="R_DATE"
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行件数">
                                </el-table-column>
                                <el-table-column
                                  prop="GROS_WT"
                                  label="净重">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM2"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="WAREHOUSE_CODE"
                                  label="海关状态">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行类别">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='箱跟踪'"
                                :data="xgzList"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="TDH"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="ZWCM"
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="HCHC"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="JCCSJ"
                                  label="移动日期">
                                </el-table-column>
                                <el-table-column
                                  prop="JCCLBMC"
                                  label="移动类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="CCDWMC"
                                  label="移出单位">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="JCDWMC"
                                  label="移入单位">
                                </el-table-column>
                                <el-table-column
                                  prop="QFH"
                                  label="铅封号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="EDI_FILE_READ_TIME"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="车号">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-在途监管信息'"
                                :data="ztjgListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="启运地">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="目的地">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="海关回执">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-疏港分流申请'"
                                :data="sgflListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="卸货地代码">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="分流目的地">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="目的地关区代码">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="地理位置">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-分拨分流放行'"
                                :data="fbflfxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="业务类型说明">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-分拨分流运抵'"
                                :data="fbflydListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="卸货地代码">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="卸货地关区">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="报文类型">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="发送单位">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              <el-table
                                v-show="selectedTable=='进口-海关报关单放行'"
                                :data="bgdfxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="250"
                                  prop=""
                                  label="报关单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="分提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="关区代码">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="放行件数">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="净重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="海关状态">
                                </el-table-column>
                              </el-table>
                            </div>
                          </div>
                        </div>
                        <div v-show="isallinfo&&type=='CK'">
                          <div id="mtxxList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-码头信息</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                    :data="mtxxListck"
                                    style="width: 100%">
                                    <el-table-column
                                    width="130"
                                      prop="TDH"
                                      label="提单号">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="XH"
                                      label="箱号">
                                    </el-table-column>
                                    <el-table-column
                                      prop="ZHGM"
                                      label="装货港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XHGM"
                                      label="卸货港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MDGM"
                                      label="目的港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XZDM"
                                      label="箱站代码">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop=""
                                      label="当前站名称">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DFSJ"
                                      label="到发时间">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XSGSM"
                                      label="箱属">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CC"
                                      label="尺寸">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XX"
                                      label="箱型">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="QFH1"
                                      label="铅封号">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MZ"
                                      label="整箱重">
                                    </el-table-column>
                                    <el-table-column
                                      prop="KZ"
                                      label="空重">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MTFX"
                                      label="码头放行">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DQZTMC"
                                      label="当前状态">
                                    </el-table-column>
                                    <el-table-column
                                      prop="RGLB"
                                      label="入港类别">
                                    </el-table-column>
                                    <el-table-column
                                    width="200"
                                      prop="SJRGSJ"
                                      label="入港/单据录入时间">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CGLB"
                                      label="出港类别	">
                                    </el-table-column>
                                    <el-table-column
                                    width="150"
                                      prop="SJCGSJ"
                                      label="出港时间	">
                                    </el-table-column>
                                    <el-table-column
                                    width="200"
                                      prop="CGMX"
                                      label="出港明细	">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DCT"
                                      label="堆存天数	">
                                    </el-table-column>
                                    <el-table-column
                                      prop="GLDWMC"
                                      label="地理位置	">
                                    </el-table-column>
                                    <el-table-column
                                    width="150"
                                      prop="TSZZXQ"
                                      label="特殊装载需求">
                                    </el-table-column>
                                    <el-table-column
                                      prop="NWM"
                                      label="内外贸	">
                                      <template slot-scope="scope">
                                        <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      prop="IMO"
                                      label="危品IMO">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="LDXBZ"
                                      label="冷冻箱标志">
                                    </el-table-column>
                                    <el-table-column
                                      prop="LDWD"
                                      label="冷冻温度">
                                    </el-table-column>
                                    <el-table-column
                                      prop="SD"
                                      label="湿度">
                                    </el-table-column>
                                    <el-table-column
                                      prop="TF"
                                      label="通风">
                                    </el-table-column>
                                    <el-table-column
                                      prop="WPBZ"
                                      label="危品标志">
                                    </el-table-column>
                                    <el-table-column
                                      prop="GJWG"
                                      label="国际危规">
                                    </el-table-column>
                                    <el-table-column
                                      prop="RGMX"
                                      label="入港明细">
                                    </el-table-column>
                                    <el-table-column
                                      prop="SX"
                                      label="锁箱">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CG"
                                      label="超高">
                                    </el-table-column>
                                    <el-table-column
                                      prop="ZC"
                                      label="左超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="YC"
                                      label="右超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="QC"
                                      label="前超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="HC"
                                      label="后超">
                                    </el-table-column>
                                  </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="zxdList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-装箱单信息</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="zxdListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop="MTMC"
                                    label="码头名称">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="ZWCM"
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHC"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XHGM"
                                    label="卸货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XZDM"
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XSTDH"
                                    label="分单号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="NWM"
                                    label="内外贸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CC"
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XX"
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XSGSM"
                                    label="箱属">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CZFS"
                                    label="称重方式">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HZZT"
                                    label="回执状态">
                                    <template slot-scope="scope">
                                      <span>{{ scope.row.HZZT=="T"?"成功":"失败" }}</span>
                                    </template>
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop="HZMS"
                                    label="回执描述">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="TQSBBZ"
                                    label="提前申报标志">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="ydbgList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-运抵报告</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="ydbgListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHBBH"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XZDM"
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XHDGQDM"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    show-overflow-tooltip
                                    prop="HZMS"
                                    label="海关回执描述">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="IMPORT_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="HZSJ"
                                    label="海关回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="整箱重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="货重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="件数">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="ztjgList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-在途监管信息</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="ztjgListck"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="启运地">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="目的地">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="海关回执">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="hgcyListck">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-海关查验</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="hgcyListck"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="中文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="出口航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="尺寸">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="箱型">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="流向类别">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="入查验区时间">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="出查验区时间">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="bgdfxList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-海关报关单放行</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="bgdfxListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="250"
                                    prop="ENTRY_ID"
                                    label="报关单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="BILL_NO"
                                    label="分提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="FLAGHT_NO"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VOYAGE_NO"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="I_E_PORT"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="R_DATE"
                                    label="放行时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="100"
                                    prop="PASS_PACK_NO"
                                    label="放行件数">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONTA_ID"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="NET_WT"
                                    label="净重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="GROS_WT"
                                    label="毛重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="海关状态">
                                    放行
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="zzfxList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-装载放行</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="zzfxListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHC"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="RDATE"
                                    label="放行时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="100"
                                    prop="SCBZMC"
                                    label="海关指令">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="IMPORT_TIME"
                                    label="接收时间">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wlshList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-外理审核放行</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wlshListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HC"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="MAIN_BILL_NO"
                                    label="分单号">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wllhList">
                            <div class="wharf-info">
                              <div class="wharf-header"><h1><i class="iconfont">文</i>出口-外理理货报告</h1></div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wllhListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONT_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="TRANSPORT_TOOL_NAME"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VESSEL_VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TRANSPORT_TOOL_COD"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DECLARE_CUSTOMS_COD"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_NUMBER"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_TOTAL_GROSS_WEIGHT"
                                    label="重数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_CUBE"
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="EDI_FILE_READ_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop="RESPONSE_CONT_MSG"
                                    label="回执说明">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CIMO"
                                    label="回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="删除标志">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-show="isallinfo&&type=='JK'">
                          <div id="mtxxListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-码头信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="mtxxListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="ZHGM"
                                    label="装货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XHGM"
                                    label="卸货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MDGM"
                                    label="目的港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XZDM"
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="当前站名称">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DFSJ"
                                    label="到发时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XSGSM"
                                    label="箱属">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CC"
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XX"
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="QFH1"
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MZ"
                                    label="整箱重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="KZ"
                                    label="空重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MTFX"
                                    label="码头放行">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="DQZTMC"
                                    label="当前状态">
                                  </el-table-column>
                                  <el-table-column
                                    prop="RGLB"
                                    label="入港类别">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="SJRGSJ"
                                    label="入港/单据录入时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CGLB"
                                    label="出港类别	">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="SJCGSJ"
                                    label="出港时间	">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="CGMX"
                                    label="出港明细	">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DCT"
                                    label="堆存天数	">
                                  </el-table-column>
                                  <el-table-column
                                    prop="GLDWMC"
                                    label="地理位置	">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="TSZZXQ"
                                    label="特殊装载需求">
                                  </el-table-column>
                                  <el-table-column
                                    prop="NWM"
                                    label="内外贸	">
                                    <template slot-scope="scope">
                                      <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                    </template>
                                  </el-table-column>
                                  <el-table-column
                                    prop="IMO"
                                    label="危品IMO">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="LDXBZ"
                                    label="冷冻箱标志">
                                  </el-table-column>
                                  <el-table-column
                                    prop="LDWD"
                                    label="冷冻温度">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SD"
                                    label="湿度">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TF"
                                    label="通风">
                                  </el-table-column>
                                  <el-table-column
                                    prop="WPBZ"
                                    label="危品标志">
                                  </el-table-column>
                                  <el-table-column
                                    prop="GJWG"
                                    label="国际危规">
                                  </el-table-column>
                                  <el-table-column
                                    prop="RGMX"
                                    label="入港明细">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SX"
                                    label="锁箱">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CG"
                                    label="超高">
                                  </el-table-column>
                                  <el-table-column
                                    prop="ZC"
                                    label="左超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="YC"
                                    label="右超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="QC"
                                    label="前超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HC"
                                    label="后超">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="czxxList">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-场站信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="czxxListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="码头名称">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="整箱重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="空重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="海关放行">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="装货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="数据来源">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="当前状态">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="来源">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="卸船时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="去向">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="出港时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="堆存天数">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="特殊装载需求">
                                  </el-table-column>
                                  <el-table-column
                                  width="100"
                                    prop=""
                                    label="内外贸">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="yscdListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-原始舱单</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="yscdListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="MAIN_BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="BILL_OF_LADING_NO"
                                    label="分提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONTAINER_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="VESSEL_NAME_CN"
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="VESSEL_NAME_EN"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="FULL_EMPTY_ID"
                                    label="空重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CONTAINER_SIZE"
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CONTAINER_TYPE"
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SEAL_NO"
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CARGO_QUANTITY"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TOTAL_GROSS_WEIGHT"
                                    label="货重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TOTAL_VOLUMN"
                                    label="体积">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wllhListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-外理理货报告</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wllhListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONT_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="TRANSPORT_TOOL_NAME"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VESSEL_VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TRANSPORT_TOOL_COD"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DECLARE_CUSTOMS_COD"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_NUMBER"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_TOTAL_GROSS_WEIGHT"
                                    label="重数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_CUBE"
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="EDI_FILE_READ_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop="RESPONSE_CONT_MSG"
                                    label="回执说明">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CIMO"
                                    label="回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="删除标志">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wtcyList">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-查验委托信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wtcyListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="单证号码">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="检查地点">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="是否自备车">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="ztjgListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-在途监管信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="ztjgListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="启运地">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="目的地">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop=""
                                    label="海关回执">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="回执时间">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="sgflListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-疏港分流申请</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="sgflListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="IMO号">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="卸货地代码">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="分流目的地">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="目的地关区代码">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="地理位置">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="fbflfxListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-分拨分流放行</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="fbflfxListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="放行时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="放行类别">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="业务类型说明">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="fbflydListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-分拨分流运抵</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="fbflydListjk"
                                style="width: 100%">
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop=""
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="卸货地代码">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop=""
                                  label="卸货地关区">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="报文类型">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="发送单位">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                show-overflow-tooltip
                                  prop=""
                                  label="回执说明">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="回执时间">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="hgcyList">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-海关查验</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="hgcyListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="出口航次">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="流向类别">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="入查验区时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="出查验区时间">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="bgdfxListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-海关报关单放行</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="bgdfxListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="250"
                                    prop=""
                                    label="报关单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="分提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop=""
                                    label="放行时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop=""
                                    label="放行件数">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop=""
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="净重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="毛重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="海关状态">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="hgxcdfxListjk">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-提单放行（码头进口放行的依据）</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="hgxcdfxListjk"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="FLAGHT_NO"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VOYAGE_NO"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="支线英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="支线航次">
                                </el-table-column>
                                <el-table-column
                                  prop="ENTRY_ID"
                                  label="报关单号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="R_DATE"
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行件数">
                                </el-table-column>
                                <el-table-column
                                  prop="GROS_WT"
                                  label="净重">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM2"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="WAREHOUSE_CODE"
                                  label="海关状态">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行类别">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div v-show="isallinfo&&type=='ZZ'">
                          <div id="yscdList1">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-原始舱单</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="yscdListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="MAIN_BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="BILL_OF_LADING_NO"
                                    label="分提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONTAINER_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="VESSEL_NAME_CN"
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="VESSEL_NAME_EN"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="FULL_EMPTY_ID"
                                    label="空重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CONTAINER_SIZE"
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CONTAINER_TYPE"
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SEAL_NO"
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CARGO_QUANTITY"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TOTAL_GROSS_WEIGHT"
                                    label="货重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TOTAL_VOLUMN"
                                    label="体积">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="mtxxList1">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-码头信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="mtxxListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="ZHGM"
                                    label="装货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XHGM"
                                    label="卸货港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MDGM"
                                    label="目的港">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XZDM"
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop=""
                                    label="当前站名称">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DFSJ"
                                    label="到发时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XSGSM"
                                    label="箱属">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CC"
                                    label="尺寸">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XX"
                                    label="箱型">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="QFH1"
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MZ"
                                    label="整箱重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="KZ"
                                    label="空重">
                                  </el-table-column>
                                  <el-table-column
                                    prop="MTFX"
                                    label="码头放行">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="DQZTMC"
                                    label="当前状态">
                                  </el-table-column>
                                  <el-table-column
                                    prop="RGLB"
                                    label="入港类别">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="SJRGSJ"
                                    label="入港/单据录入时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CGLB"
                                    label="出港类别	">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="SJCGSJ"
                                    label="出港时间	">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="CGMX"
                                    label="出港明细	">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DCT"
                                    label="堆存天数	">
                                  </el-table-column>
                                  <el-table-column
                                    prop="GLDWMC"
                                    label="地理位置	">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="TSZZXQ"
                                    label="特殊装载需求">
                                  </el-table-column>
                                  <el-table-column
                                    prop="NWM"
                                    label="内外贸	">
                                    <template slot-scope="scope">
                                      <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                    </template>
                                  </el-table-column>
                                  <el-table-column
                                    prop="IMO"
                                    label="危品IMO">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="LDXBZ"
                                    label="冷冻箱标志">
                                  </el-table-column>
                                  <el-table-column
                                    prop="LDWD"
                                    label="冷冻温度">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SD"
                                    label="湿度">
                                  </el-table-column>
                                  <el-table-column
                                    prop="TF"
                                    label="通风">
                                  </el-table-column>
                                  <el-table-column
                                    prop="WPBZ"
                                    label="危品标志">
                                  </el-table-column>
                                  <el-table-column
                                    prop="GJWG"
                                    label="国际危规">
                                  </el-table-column>
                                  <el-table-column
                                    prop="RGMX"
                                    label="入港明细">
                                  </el-table-column>
                                  <el-table-column
                                    prop="SX"
                                    label="锁箱">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CG"
                                    label="超高">
                                  </el-table-column>
                                  <el-table-column
                                    prop="ZC"
                                    label="左超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="YC"
                                    label="右超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="QC"
                                    label="前超">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HC"
                                    label="后超">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wllhList1">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-外理理货报告</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wllhListjk"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONT_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="TRANSPORT_TOOL_NAME"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VESSEL_VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TRANSPORT_TOOL_COD"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DECLARE_CUSTOMS_COD"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_NUMBER"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_TOTAL_GROSS_WEIGHT"
                                    label="重数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_CUBE"
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="EDI_FILE_READ_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop="RESPONSE_CONT_MSG"
                                    label="回执说明">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CIMO"
                                    label="回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="删除标志">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="tdfxList1">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>进口-提单放行</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                :data="tdfxList1"
                                style="width: 100%">
                                <el-table-column
                                width="150"
                                  prop="BILL_NO"
                                  label="提单号">
                                </el-table-column>
                                <el-table-column
                                width="130"
                                  prop="XH"
                                  label="箱号">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="FLAGHT_NO"
                                  label="英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop="VOYAGE_NO"
                                  label="航次">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop=""
                                  label="支线英文船名">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="支线航次">
                                </el-table-column>
                                <el-table-column
                                  prop="ENTRY_ID"
                                  label="报关单号">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="R_DATE"
                                  label="放行时间">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行件数">
                                </el-table-column>
                                <el-table-column
                                  prop="GROS_WT"
                                  label="净重">
                                </el-table-column>
                                <el-table-column
                                width="200"
                                  prop="YWCM2"
                                  label="毛重">
                                </el-table-column>
                                <el-table-column
                                width="150"
                                  prop="WAREHOUSE_CODE"
                                  label="海关状态">
                                </el-table-column>
                                <el-table-column
                                  prop=""
                                  label="放行类别">
                                </el-table-column>
                              </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="xgzList">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>箱跟踪</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  v-show="selectedTable=='箱跟踪'"
                                  :data="xgzList"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="ZWCM"
                                    label="中文船名">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHC"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="JCCSJ"
                                    label="移动日期">
                                  </el-table-column>
                                  <el-table-column
                                    prop="JCCLBMC"
                                    label="移动类别">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="CCDWMC"
                                    label="移出单位">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="JCDWMC"
                                    label="移入单位">
                                  </el-table-column>
                                  <el-table-column
                                    prop="QFH"
                                    label="铅封号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="EDI_FILE_READ_TIME"
                                    label="毛重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="车号">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="mtxxList2">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>出口-码头信息</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                    :data="mtxxListck"
                                    style="width: 100%">
                                    <el-table-column
                                    width="130"
                                      prop="TDH"
                                      label="提单号">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="XH"
                                      label="箱号">
                                    </el-table-column>
                                    <el-table-column
                                      prop="ZHGM"
                                      label="装货港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XHGM"
                                      label="卸货港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MDGM"
                                      label="目的港">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XZDM"
                                      label="箱站代码">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop=""
                                      label="当前站名称">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DFSJ"
                                      label="到发时间">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XSGSM"
                                      label="箱属">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CC"
                                      label="尺寸">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XX"
                                      label="箱型">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="QFH1"
                                      label="铅封号">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MZ"
                                      label="整箱重">
                                    </el-table-column>
                                    <el-table-column
                                      prop="KZ"
                                      label="空重">
                                    </el-table-column>
                                    <el-table-column
                                      prop="MTFX"
                                      label="码头放行">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DQZTMC"
                                      label="当前状态">
                                    </el-table-column>
                                    <el-table-column
                                      prop="RGLB"
                                      label="入港类别">
                                    </el-table-column>
                                    <el-table-column
                                    width="200"
                                      prop="SJRGSJ"
                                      label="入港/单据录入时间">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CGLB"
                                      label="出港类别	">
                                    </el-table-column>
                                    <el-table-column
                                    width="150"
                                      prop="SJCGSJ"
                                      label="出港时间	">
                                    </el-table-column>
                                    <el-table-column
                                    width="200"
                                      prop="CGMX"
                                      label="出港明细	">
                                    </el-table-column>
                                    <el-table-column
                                      prop="DCT"
                                      label="堆存天数	">
                                    </el-table-column>
                                    <el-table-column
                                      prop="GLDWMC"
                                      label="地理位置	">
                                    </el-table-column>
                                    <el-table-column
                                    width="150"
                                      prop="TSZZXQ"
                                      label="特殊装载需求">
                                    </el-table-column>
                                    <el-table-column
                                      prop="NWM"
                                      label="内外贸	">
                                      <template slot-scope="scope">
                                        <span>{{ scope.row.NWM=="W"?"外贸":"内贸" }}</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      prop="IMO"
                                      label="危品IMO">
                                    </el-table-column>
                                    <el-table-column
                                    width="130"
                                      prop="LDXBZ"
                                      label="冷冻箱标志">
                                    </el-table-column>
                                    <el-table-column
                                      prop="LDWD"
                                      label="冷冻温度">
                                    </el-table-column>
                                    <el-table-column
                                      prop="SD"
                                      label="湿度">
                                    </el-table-column>
                                    <el-table-column
                                      prop="TF"
                                      label="通风">
                                    </el-table-column>
                                    <el-table-column
                                      prop="WPBZ"
                                      label="危品标志">
                                    </el-table-column>
                                    <el-table-column
                                      prop="GJWG"
                                      label="国际危规">
                                    </el-table-column>
                                    <el-table-column
                                      prop="RGMX"
                                      label="入港明细">
                                    </el-table-column>
                                    <el-table-column
                                      prop="SX"
                                      label="锁箱">
                                    </el-table-column>
                                    <el-table-column
                                      prop="CG"
                                      label="超高">
                                    </el-table-column>
                                    <el-table-column
                                      prop="ZC"
                                      label="左超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="YC"
                                      label="右超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="QC"
                                      label="前超">
                                    </el-table-column>
                                    <el-table-column
                                      prop="HC"
                                      label="后超">
                                    </el-table-column>
                                  </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="ydbgList2">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>出口-运抵报告</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="ydbgListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHBBH"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XZDM"
                                    label="箱站代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="XHDGQDM"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    show-overflow-tooltip
                                    prop="HZMS"
                                    label="海关回执描述">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="IMPORT_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="HZSJ"
                                    label="海关回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="整箱重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="货重">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="件数">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="zzfxList2">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>出口-装载放行</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="zzfxListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="TDH"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="XH"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="YWCM"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="HCHC"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CIMO"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="RDATE"
                                    label="放行时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="100"
                                    prop="SCBZMC"
                                    label="海关指令">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="IMPORT_TIME"
                                    label="接收时间">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          <div id="wllhList2">
                            <div class="wharf-info">
                              <div class="wharf-header">
                                <h1><i class="iconfont">文</i>出口-外理理货报告</h1>
                              </div>
                              <div class="ant-table-wrapper css-eq3tly">
                                <el-table
                                  :data="wllhListck"
                                  style="width: 100%">
                                  <el-table-column
                                  width="150"
                                    prop="BILL_NO"
                                    label="提单号">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="CONT_NO"
                                    label="箱号">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                    prop="TRANSPORT_TOOL_NAME"
                                    label="英文船名">
                                  </el-table-column>
                                  <el-table-column
                                    prop="VESSEL_VOYAGE_NUMBER"
                                    label="航次">
                                  </el-table-column>
                                  <el-table-column
                                  width="130"
                                    prop="TRANSPORT_TOOL_COD"
                                    label="IMO号">
                                  </el-table-column>
                                  <el-table-column
                                    prop="DECLARE_CUSTOMS_COD"
                                    label="关区代码">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_NUMBER"
                                    label="件数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_TOTAL_GROSS_WEIGHT"
                                    label="重数">
                                  </el-table-column>
                                  <el-table-column
                                    prop="CARGO_CUBE"
                                    label="体积">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="EDI_FILE_READ_TIME"
                                    label="发送时间">
                                  </el-table-column>
                                  <el-table-column
                                  width="200"
                                  show-overflow-tooltip
                                    prop="RESPONSE_CONT_MSG"
                                    label="回执说明">
                                  </el-table-column>
                                  <el-table-column
                                  width="150"
                                    prop="CIMO"
                                    label="回执时间">
                                  </el-table-column>
                                  <el-table-column
                                    prop=""
                                    label="删除标志">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import '/src/assets/css/index.css'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    
  },
  created(){
    
    

  },
  data() {
    return {
      type:'',
      boxnumber:'',
      isallinfo:true,
      boxdata:'',
      liList:[],
      selectedTable:null,

    }
  },
  methods:{
    search(){
      if(!this.$refs.boxinput.value)
      return false
      this.boxnumber=this.$refs.boxinput.value
      //  axios.post(`http://localhost:20486/ApiInterface/GetBoxInfo?boxNumber=${this.boxnumber}&type=${this.type}`)
       axios.post(`https://etg.feiliankeji.cn/ApiInterface/GetBoxInfo?boxNumber=${this.boxnumber}&type=${this.type}`)
      .then(response =>{
        const res = response.data;
        if(res.code==200){
          console.log(res.info)
          this.boxdata=JSON.parse(res.info)
          this.type=this.boxdata.head.jckType
          this.liList=[]
          this.boxdata.res.forEach((item,index) => {
            this.liList.push({
              id:item.id,
              title:item.title,
              active:!!item.data&&item.data.length>0?true:false,
              index:index
            })
          });
          this.selectedTable=this.liList.filter(p=>p.active).length>0? this.liList.filter(p=>p.active)[0].title:null

        }else{
          this.$message({
            message: res.info,
            type: 'warning'
          });
        }

      })
      .catch((error)=>{
        console.error('Error fetching data', error);
        this.$message({
            message: '内部接口异常',
            type: 'error'
          });
      })

    },
    handleListClick(title){
      console.log(title)
      console.log(this.boxdata.res.find(p=>p.title==title))
      this.selectedTable=title

    },
    typechange(){
      this.search()
    }

  },
  computed:{
    //#region 出口

    //出口-码头信息
    mtxxListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-码头信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-码头信息').data
    },
    //出口-装箱单信息
    zxdListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-装箱单信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-装箱单信息').data
    },
    //出口-运抵报告
    ydbgListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-运抵报告')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-运抵报告').data
    },
    //出口-海关查验
    hgcyListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-海关查验')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-海关查验').data
    },
    //出口-海关报关单放行
    bgdfxListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-海关报关单放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-海关报关单放行').data
    },
    //出口-装载放行
    zzfxListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-装载放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-装载放行').data
    },
    //出口-外理审核放行
    wlshListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-外理审核放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-外理审核放行').data
    },
    //出口-外理理货报告
    wllhListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-外理理货报告')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-外理理货报告').data
    },
    //出口-箱跟踪
    xgzListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-箱跟踪')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-箱跟踪').data
    },
    //出口-在途监管信息
    ztjgListck(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='出口-在途监管信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='出口-在途监管信息').data
    },
    //#endregion
    
    //#region 进口

    //进口-码头信息
    mtxxListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-码头信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-码头信息').data
    },
    //进口-场站信息
    czxxListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-场站信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-场站信息').data
    },
    //进口-原始舱单
    yscdListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-原始舱单')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-原始舱单').data
    },
    //进口-箱跟踪
    xgzListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-箱跟踪')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-箱跟踪').data
    },
    //进口-外理理货报告
    wllhListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-外理理货报告')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-外理理货报告').data
    },
    //进口-查验委托信息
    wtcyListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-查验委托信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-查验委托信息').data
    },
    //进口-疏港分流申请
    sgflListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-疏港分流申请')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-疏港分流申请').data
    },
    //进口-分拨分流运抵
    fbflydListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-分拨分流运抵')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-分拨分流运抵').data
    },
    //进口-分拨分流放行
    fbflfxListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-分拨分流放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-分拨分流放行').data
    },
    //进口-海关查验
    hgcyListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-海关查验')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-海关查验').data
    },
    //进口-海关报关单放行
    bgdfxListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-海关报关单放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-海关报关单放行').data
    },
    //进口-提单放行（码头进口放行的依据）
    hgxcdfxListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-提单放行（码头进口放行的依据）')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-提单放行（码头进口放行的依据）').data
    },
    //进口-在途监管信息
    ztjgListjk(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-在途监管信息')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-在途监管信息').data
    },
    //#endregion

    //#region 中转
    //箱跟踪
    xgzList(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='箱跟踪')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='箱跟踪').data
    },
    //进口-提单放行
    tdfxList1(){
      if(!this.boxdata||!this.boxdata.res.find(p=>p.title=='进口-提单放行')?.data){
        return []
      }
      return this.boxdata.res.find(p=>p.title=='进口-提单放行').data
    },

    //#endregion

  },
  filters: {
    emptyToPlaceholder(value) {
      if (!value) {
        return '暂无数据';
      }
      return value;
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

</style>
